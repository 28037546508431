import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, Link, useLocation, Navigate } from 'react-router-dom';
import { filter } from 'lodash';
import { MapContainer, Polygon, TileLayer } from 'react-leaflet';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { CiTrash } from 'react-icons/ci';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Box,
  Modal,
} from '@mui/material';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// components
import Swal from 'sweetalert2';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections

// mock
import USERLIST from '../_mock/user';
import {
  deleteCategory,
  deleteParking,
  deleteQuestion,
  deleteUploads,
  getAllQuestions,
  getCategories,
  getDoorList,
  getParking,
  getUsers,
} from '../helper/backend_helper';
import appConfig from '../config';
import { SolutionListHead, SolutionListToolbar } from '../components/addSolution';


const TABLE_HEAD = [
  { id: '', label: 'Name', alignRight: true },
  { id: '', label: 'Color', alignRight: true },
  { id: '', label: 'Description', alignRight: true },
  { id: '', label: 'Italian Description', alignRight: true },
  { id: '', label: 'Action', alignRight: false },
];


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "80%",
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ParkingAreaPage() {
  const navigate = useNavigate();
  // const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [clickedRowData, setClickedRowData] = useState(null);
  // const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [users, setUsers] = useState([]);

  async function fetchData() {
    try {
      setLoading(true);
      const usersResponse = await getParking();
      const filteredUsers = usersResponse?.data?.data;
      setUsers(filteredUsers);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (event, row) => {
    try {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to delete this Parking?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });
      if (confirmed.isConfirmed) {
        const headers = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const header = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const data = {
          filename: row?.image,
        };
        // const delUploads = await deleteUploads(data , header);

        const response = await deleteParking(row?._id, headers);
        Swal.fire({
          icon: 'success',
          title: 'Parking deleted successfully',
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'An error occur while deleting Parking',
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setClickedRowData(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  // useEffect(() => {
  //   let isMounted = true;

  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       const response = await getUsers();

  //       if (isMounted) {
  //         setUsers(response.data.data.result);
  //       }

  //       setLoading(false);
  //     } catch (error) {
  //       setLoading(false);
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();

  //   // Cleanup function
  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  // const encodedData = encodeURIComponent(JSON.stringify(clickedRow));
  return (
    <>
      <Helmet>
        <title> parking </title>
      </Helmet>

      <Card sx={{ padding: 3 }}>
        <Stack mt={3} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Parking</Typography>
        <Stack direction="row" gap={2}>
          <Button
            sx={{
              marginBottom: 3,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleOpen}
          >
            Map
          </Button>
          <Button
            sx={{
              marginBottom: 3,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            endIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => navigate('/parkingArea/add_parkingArea')}
          >
            Add Parking
          </Button>
          </Stack>
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <SolutionListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { _id, coordinates, color, description, italianDescription, name } = row;
                  const points = coordinates?.coordinates || [];
                  return (
                    <TableRow hover key={_id}>
                      {/* <TableCell sx={{ width: 650 }} align="left">
                        {points.length > 0 ? (
                          <ul style={{ margin: 0, paddingLeft: '16px' }}>
                            {points.map((point, index) => (
                              <li key={index}>
                                [<strong>{point[0].toFixed(6)}</strong>, <strong>{point[1].toFixed(6)}</strong>]
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <span>No coordinates available</span>
                        )}
                      </TableCell> */}
                       <TableCell sx={{ width: 650 }} align="left">
                        {name}
                      </TableCell>
                      <TableCell sx={{ width: 650,color:`${color}` }} align="left">
                        {color}
                      </TableCell>
                      <TableCell sx={{ width: 650 }} align="left">
                        {description}
                      </TableCell>
                      <TableCell sx={{ width: 650 }} align="left">
                        {italianDescription}
                      </TableCell>
                      <TableCell align="center">
                        <Stack
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Button
                            onClick={() => navigate('/parkingArea/update_parkingArea', { state: { userData: row } })}
                            sx={{
                              boxShadow: 'none',
                              backgroundColor: '#B5FFD8',
                              color: '#009245',
                              '&:hover': {
                                backgroundColor: '#B5FFD8',
                                boxShadow: 'none',
                              },
                            }}
                            size="small"
                            variant="contained"
                            startIcon={<MdOutlineEdit />}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={(event) => handleDelete(event, row)}
                            sx={{
                              boxShadow: 'none',
                              backgroundColor: '#FFC6C6',
                              color: '#DC2626',
                              '&:hover': {
                                backgroundColor: '#FFC6C6',
                                boxShadow: 'none',
                              },
                            }}
                            size="small"
                            variant="contained"
                            startIcon={<CiTrash />}
                          >
                            Delete
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
                {loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7}>
                      <CircularProgress size="20px" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>




      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <MapContainer
             center={[40.91551033210304,  16.759643554687504]} zoom={10}
             style={{ height: '100%', width: '100%' }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; OpenStreetMap contributors"
        />

        
     

      

      {users?.map((park, index) => (
        <Polygon
       key={index}
       positions={park?.coordinates?.coordinates?.map(([lng, lat]) => [lat, lng])} // Converting [lng, lat] to [lat, lng] for Leaflet
       pathOptions={{
        color: park.color || '#8ecae6', // Use parking's color or a default
      fillColor: park.color || '#bde0fe', // Use parking's color or a default
         fillOpacity: 0.5, // Opacity of fill
       }}
      />
         ))}


       
      </MapContainer>
        </Box>
      </Modal>


    </>
  );
}
