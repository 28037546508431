import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { useNavigate } from 'react-router-dom';
  import { FaArrowLeftLong } from 'react-icons/fa6';
  import { SlCloudUpload } from 'react-icons/sl';
  import Swal from 'sweetalert2';
  import { LoadingButton } from '@mui/lab';
  import { createAssignRefill, getAllRefill, createQuestion, getBnbList, uploadFile } from '../../helper/backend_helper';
  
  const AddAssignRefill = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ profileImage: null });
    const [refillItem, setRefillItem] = useState('');
    const [bnb, setBnB] = useState([]);
    const [refill, setRefill] = useState([]);
  
   
    const [loading, setLoading] = useState(false);
    const handleFileChange = (e) => {
      const { name, files } = e.target;
  
      setFormData({
        ...formData,
        [name]: files[0],
      });
    };
  
    const [users, setUsers] = useState([]);
    console.log('users', users);
    async function fetchData() {
      try {
        const usersResponse = await getBnbList();
        // setUsers(usersResponse?.data?.data);
        setUsers(
          usersResponse?.data?.data?.sort((a, b) => {
            const nameA = a.BnBShortName?.toLowerCase() || ""; // Handle undefined or null values
            const nameB = b.BnBShortName?.toLowerCase() || "";
            return nameA.localeCompare(nameB); // Sort alphabetically
          })
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }


    async function fetchRefill() {
        try {
          const usersResponse = await getAllRefill();
          // setRefill(usersResponse?.data?.data);
          setRefill(
            usersResponse?.data?.data?.sort((a, b) => {
              const nameA = a.name?.toLowerCase() || ""; // Handle undefined or null values
              const nameB = b.name?.toLowerCase() || "";
              return nameA.localeCompare(nameB); // Sort alphabetically
            })
          );
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
  
    useEffect(() => {
      fetchData();
      fetchRefill()
    }, []);
  
    const handleSave = async () => {
      try {
        setLoading(true);
        
        
        
      
  
  
        if (bnb.length===0) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'BnB Name is required',
          });
          setLoading(false);
          return;
        }
        if (!refillItem) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Refill Item is required',
          });
          setLoading(false);
          return;
        }
       
      
       
    
  
        const Categorydata = {
          bnb,
          refillItem,
        }

        const Categoryheaders = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
  
        const response = await createAssignRefill(Categorydata, Categoryheaders);
  
     
  
        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'created successfully',
        });
        setRefillItem('');
        setBnB([]);
        
       
  
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${error?.response?.data?.errors[0]?.message}`,
        });
      }
    };
  
    return (
      <>
        <Helmet>
          <title> Add Assign Refill </title>
        </Helmet>
        <Card sx={{ padding: 3 }}>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Assign Refill</Typography>
          </Stack>
  
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '50ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
                <Select
                  labelId="demo-multi-select-label"
                  id="demo-multi-select"
                  multiple
                  value={bnb} // This will be an array
                  onChange={(e) => setBnB(e.target.value)}
                  renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
                >
                  {users?.map((user) => (
                    <MenuItem key={user._id} value={user.BnBShortName}>
                      <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                      {user.BnBShortName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
           
            <Stack mt={3}>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>Refill Item</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={refillItem}
                  // label="BnB"
                  onChange={(e) => setRefillItem(e.target.value)}
                >
                  {refill?.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          
          
  
        
  
       
          </Box>
          <LoadingButton
            sx={{
              width: 100,
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Save
          </LoadingButton>
        </Card>
      </>
    );
  };
  
  export default AddAssignRefill;
  