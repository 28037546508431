import React, { useState } from 'react';

import Sidebar from './Sidebar';
import ChatArea from './ChatArea';
import DetailsPanel from './DetailsPanel';

const Chat = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDetailsPanelOpen, setIsDetailsPanelOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDetailsPanel = () => {
    setIsDetailsPanelOpen(!isDetailsPanelOpen);
  };

  return (
    <div className="flex bg-[#f4f4f4] h-screen">
      {/* Sidebar Overlay */}
      <div
        className={`fixed inset-0 z-20 bg-black bg-opacity-50 transition-opacity ${
          isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={toggleSidebar}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') toggleSidebar();
        }}
      />

      {/* Sidebar */}
      <div
        className={`fixed top-20 left-0 z-30 bg-white h-full w-3/4 max-w-xs transform transition-transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:static lg:translate-x-0 lg:block lg:w-1/4`}
      >
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="w-full lg:w-1/2">
        <ChatArea />
      </div>

      {/* Details Panel Overlay */}
      <div
        className={`fixed inset-0 z-20 bg-black bg-opacity-50 transition-opacity ${
          isDetailsPanelOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={toggleDetailsPanel}
        role="button"
        tabIndex="0"
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') toggleDetailsPanel();
        }}
      />

      {/* Details Panel */}
      <div
        className={`fixed top-20 right-0 z-30 bg-white h-full w-3/4 max-w-xs transform transition-transform ${
          isDetailsPanelOpen ? 'translate-x-0' : 'translate-x-full'
        } lg:static lg:translate-x-0 lg:block lg:w-1/4`}
      >
        <DetailsPanel />
      </div>

      {/* Sidebar Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="fixed top-16 left-4 z-40 p-2 text-black rounded-full lg:hidden"
      >
        ☰
      </button>

      {/* Details Panel Toggle Button */}
      <button
        onClick={toggleDetailsPanel}
        className="fixed top-16 right-4 z-40 p-2 text-lg text-black rounded-full lg:hidden"
      >
        🛈
      </button>
    </div>
  );
};

export default Chat;
