import React from 'react';
import bnb from '../../assets/airbnb.svg';
import B from '../../assets/b.svg';
import whatsApp from '../../assets/whatsApp.svg';

const Sidebar = () => {
  const chats = [
    {
      name: 'John Smith',
      details: 'Tatiana - Historic House',
      dates: '14 Jun 2024 - 28 May 2024',
      tags: ['Future Guest'],
      icon: 'airbnb', // Add corresponding icons for your needs
      time: '19:11',
      unreadCount: 4,
    },
    {
      name: 'Mark Mike',
      details: 'Multiple BnB',
      dates: '14 Jun 2024 - 28 May 2024',
      tags: ['Multiple', 'Emergency'],
      icon: 'airbnb',
      time: '19:42',
    },
    {
      name: 'Pinco Pallino',
      details: 'Bianca - Luxury Penthouse',
      dates: '14 Sep 2024 - 26 May 2024',
      tags: ['Past Guest', 'Bad Review'],
      icon: 'booking',
      time: '11:26',
    },
    // Add other chat objects based on the image...
  ];

  return (
    <div className=" bg-gray-100 h-screen overflow-y-auto p-4 scrollbar-hide">
      {/* Search Bar */}
      <div className=" relative">
        {/* Search Icon */}
        <span className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
          <svg
            className="w-3 h-3 text-gray-400"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z" />
          </svg>
        </span>

        {/* Input Field */}
        <input
          type="text"
          placeholder="Search chat"
          className="w-full text-sm p-1 placeholder:text-xs pl-8 border outline-none border-none rounded-lg"
        />
      </div>
      <div className="my-2 border-b-[1px] border-gray-300 " />
      {/* Filter Tags */}

      <div className="flex my-2 gap-2">
        <p className="text-sm">Filter tag:</p>
        <div>
          <select
            id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option selected>Choose an option</option>
            <option value="US">Past Guest</option>
            <option value="CA">Checkout</option>
            <option value="FR">Staying</option>
            <option value="DE">Checkin</option>
          </select>
        </div>
      </div>

      {/* Chat List */}
      <div className="relative flex items-center space-x-2 p-2 bg-white rounded-2xl ">
        <span className="absolute top-0 right-0 bg-black text-white text-xs font-bold rounded-full px-2 py-1 transform translate-x-2 -translate-y-1">
          4
        </span>
        <div>
          <img className="w-6 h-6  object-cover" src={bnb} alt="John Smith" />
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex  justify-between items-center">
            <h4 className="font-bold text-gray-900">John Smith</h4>
            <p className="text-[10px] mr-4 text-gray-500">18:11</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Tatiana - Historic House</p>
            <p className="text-xs text-gray-400">14 Jun 2024 - 28 May 2024</p>
          </div>
          <div className="flex gap-2">
            <span className="bg-[#70D4FF] text-black text-[10px] font-thin  px-2 py-0.5 rounded-2xl dark:bg-blue-900 dark:text-blue-300">
              Future guest
            </span>
          </div>
        </div>
      </div>

      <div className="relative mt-3 flex items-center space-x-2 p-2 bg-white rounded-2xl">
        <span className="absolute top-0 right-0 bg-black text-white text-xs font-bold rounded-full px-2 py-1 transform translate-x-2 -translate-y-1">
          2
        </span>
        <div>
          <img className="w-6 h-6  object-cover" src={bnb} alt="John Smith" />
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex justify-between items-center">
            <h4 className="font-bold text-gray-900">Mark Mike</h4>
            <p className="text-[10px] mr-4 text-gray-500">18:11</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Tatiana - Historic House</p>
            <p className="text-xs text-gray-400">14 Jun 2024 - 28 May 2024</p>
          </div>
          <div className="flex gap-2">
            <span className="bg-[#FBE581] text-black text-[10px] font-thin  px-2 py-0.5 rounded-2xl dark:bg-blue-900 dark:text-blue-300">
              Multiple
            </span>
            <span className="bg-[#FF6C6C] text-black text-[10px] font-thin  px-2 py-0.5 rounded-2xl dark:bg-blue-900 dark:text-blue-300">
              Emergency
            </span>
          </div>
        </div>
      </div>

      <div className="relative mt-3 flex items-center space-x-2 p-2 bg-white rounded-2xl">
        {/* <span className="absolute top-0 right-0 bg-black text-white text-xs font-bold rounded-full px-2 py-1 transform translate-x-2 -translate-y-1">
          2
        </span> */}
        <div className="">
          <img className="w-6 h-6  object-cover" src={B} alt="John Smith" />
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex justify-between items-center">
            <h4 className="font-bold text-gray-900">Mark Mike</h4>
            <p className="text-[10px] mr-4 text-gray-500">18:11</p>
          </div>
          <div>
            <p className="text-sm text-gray-500">Tatiana - Historic House</p>
            <p className="text-xs text-gray-400">14 Jun 2024 - 28 May 2024</p>
          </div>
          <div className="flex gap-2">
            <span className="bg-[#AEAEB4] text-black text-[10px] font-thin  px-2 py-0.5 rounded-2xl dark:bg-blue-900 dark:text-blue-300">
              Past guest
            </span>
            <span className="bg-[#FF6C6C] text-black text-[10px] font-thin  px-2 py-0.5 rounded-2xl dark:bg-blue-900 dark:text-blue-300">
              Bad Review
            </span>
          </div>
        </div>
      </div>

      <div className="relative mt-3 flex items-center space-x-2 p-2 bg-white rounded-2xl">
        {/* <span className="absolute top-0 right-0 bg-black text-white text-xs font-bold rounded-full px-2 py-1 transform translate-x-2 -translate-y-1">
          2
        </span> */}
        <div className="">
          <img className="w-6 h-6  object-cover" src={whatsApp} alt="John Smith" />
        </div>
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex justify-between items-center">
            <h4 className="font-bold text-gray-900">+39 214578991</h4>
            <p className="text-[10px] mr-4 text-gray-500">18:11</p>
          </div>
          {/* <div>
            <p className="text-sm text-gray-500">Tatiana - Historic House</p>
            <p className="text-xs text-gray-400">14 Jun 2024 - 28 May 2024</p>
          </div> */}
          <div className="flex gap-2">
            <span className="bg-[#FF97F8] text-black text-[10px] font-thin  px-2 py-0.5 rounded-2xl dark:bg-blue-900 dark:text-blue-300">
              Request
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
