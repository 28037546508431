import { useEffect, useRef, useState } from 'react';
import chatgpt from '../../assets/chatgpt.svg';

const ChatArea = () => {
  const [messages, setMessages] = useState([]); // To store messages
  const [message, setMessage] = useState(''); // To store the input value
  const messagesEndRef = useRef(null);

  // Scroll to the bottom function
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll to bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const handleSend = () => {
    if (message.trim()) {
      setMessages([...messages, {message,type:"right"}]); // Add the new message to the list
      setMessage(''); // Clear the input field
    }
  };

  return (
    <div className="p-4 flex h-screen flex-col">
      {/* Chat Header */}
      <div className="mb-4 flex justify-between">
        <h3 className="font-bold text-lg">Pinco Pallino</h3>
        <div className="flex flex-col items-end">
          <p className="text-sm text-gray-500">+39 2454878445</p>
          <p className="text-sm text-gray-500">pincopallino@gmail.com</p>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto  rounded  scrollbar-hide space-y-3 bg-gray-100">
        {messages.map((msg, index) => (
          <>
            <div key={index} style={{ whiteSpace: "pre-wrap" }} className={`bg-white 
              ${msg.type==="right"?"ml-auto":"mr-auto"}
               text-black font-thin text-xs p-3 rounded-xl max-w-xs`}>
              {msg.message}
            </div>
           {msg.type==="right"?(
             <div className='flex items-center px-2 ml-auto max-w-xs justify-between'>
             <h1 className='text-[8px] -mt-2'>WhatsApp / OTA</h1>
             <h1 className='text-[8px] -mt-2'>ChatGPT</h1>
             <h1 className='text-[8px] -mt-2'>9/12/2024 9:21</h1>
           </div>
           ):( <div className='flex items-center px-2 mr-auto max-w-xs justify-between'>
            <h1 className='text-[8px] -mt-2'>WhatsApp</h1>
            <h1 className='text-[8px] -mt-2'>9/12/2024 9:21</h1>
          </div>)}
          </>
        ))}
         <div ref={messagesEndRef} />
      </div>

      {/* Chat Input */}
      <div className='sticky bottom-0'>
      <div className="mt-4">
        <textarea
          rows="3"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          className="w-full p-2 text-xs font-light  rounded-2xl outline-none placeholder:text-xs placeholder:font-light"
        />
      </div>
      <div className="flex my-1 space-x-2">
        <button className="border border-black flex-1 px-1 py-1 text-black rounded-full hover:bg-gray-100 text-xs">
          OTA
        </button>
        <button className="border border-black flex-1 px-1 py-1 text-black rounded-full hover:bg-gray-100 text-xs">
          Email
        </button>
        <button className="border border-black flex-1 px-1 py-1 text-black rounded-full hover:bg-gray-100 text-xs">
          WhatsApp
        </button>
        <button className="border border-black flex-1 px-1 py-1 text-black rounded-full hover:bg-gray-100 text-xs">
          SMS
        </button>
        <button
          onClick={handleSend}
          className="border border-black bg-black flex-[2] px-1 py-1 text-white rounded-full text-xs"
        >
          Send Message
        </button>
      </div>

      <div className="my-3 flex gap-2">
        <textarea
          rows="3"
          type="text"
          placeholder="Type your prompt..."
          className="w-full p-2 text-xs font-light rounded-2xl outline-none placeholder:text-xs placeholder:font-light"
        />
        <div className="flex flex-col items-center justify-center gap-1">
          <img className="w-7 h-7  object-cover" src={chatgpt} alt="John Smith" />
          <button className="border border-black bg-black  px-8 max-sm:px-4 py-1 text-white rounded-full  text-xs">Generate</button>
        </div>
      </div>
      </div>
    </div>
  );
};
export default ChatArea;
