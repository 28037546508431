import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, Link, useLocation, Navigate } from 'react-router-dom';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { CiTrash } from 'react-icons/ci';
import { FaRegCopy } from 'react-icons/fa';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// components
import Swal from 'sweetalert2';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import appConfig from '../config';
import USERLIST from '../_mock/user';
import { deleteCleaner, deleteLuggage, deleteSpecialAccess, deleteUser, getAllCleaner, getAllLuggage, getAllSpecialAcess, getBnbList, getUsers } from '../helper/backend_helper';

// ----------------------------------------------------------------------
// const users = [
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
//   {
//     first: 'John',
//     last: 'wick',
//     email: 'johnwick@example.com',
//     phoneNo: '123-45-6781',
//   },
// ];

const TABLE_HEAD = [
  { id: '', label: 'BnB', alignRight: true },
  { id: '', label: 'Storage Name', alignRight: true },
  { id: '', label: 'Address', alignRight: true },
  { id: '', label: 'Opening Time', alignRight: true },
  { id: '', label: 'Coordinates', alignRight: true },
  { id:"", label:"Instruction Link", alignRight: true},
  { id: '', label: 'Actions', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        _user.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Luggage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [clickedRowData, setClickedRowData] = useState(null);
  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      setLoading(true);
      const usersResponse = await getAllLuggage();
      setUsers(usersResponse?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const [loading, setLoading] = useState(false);

  const handleDelete = async (_id) => {
    try {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to delete this Luggage?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });

      if (confirmed.isConfirmed) {
        const headers = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const response = await deleteLuggage(_id, headers);
        Swal.fire({
          icon: 'success',
          title: 'deleted successfully',
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'An error occurred while deleting',
      });
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  // const encodedData = encodeURIComponent(JSON.stringify(clickedRow));
  return (
    <>
      <Helmet>
        <title> Luggage </title>
      </Helmet>

      <Card sx={{ p: 3 }}>
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Luggage</Typography>

          <Button
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#3A5239',
                  '&:hover': {
                    backgroundColor: '#3A5239',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
                endIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => navigate('/luggage/add_luggage')}
              >
                Add Luggage
              </Button>
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const { _id,bnb,address,videoLink,coordinates,openingTime,storageName } = row;

                  return (
                    <TableRow hover key={_id}>
                   <TableCell align="left">
                        {bnb?.map((item, index) => (
                              <span key={index}>
                                ● {item}
                                <br />
                              </span>
                            ))}
                      </TableCell>

                      <TableCell align="left">
                     {storageName}
                     </TableCell>
                     
                     <TableCell align="left">
                     {address}
                     </TableCell>

                    <TableCell align="left">
                    {openingTime} 
                    </TableCell>
                     <TableCell align="left">
                     {coordinates?.coordinates[0]}, {coordinates?.coordinates[1]}
                     </TableCell>
                     <TableCell sx={{cursor:"pointer"}} align="left">{<a href={`${videoLink}`} target="_blank" rel="noopener noreferrer">{videoLink}</a>}</TableCell>


                      <TableCell align="center">
                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 1,
                            }}
                          >
                            <Button
                               onClick={() => navigate('/luggage/update_luggage', { state: { userData: row } })}
                              sx={{
                                boxShadow: 'none',
                                backgroundColor: '#B5FFD8',
                                color: '#009245',
                                '&:hover': {
                                  backgroundColor: '#B5FFD8',
                                  boxShadow: 'none',
                                },
                              }}
                              size="small"
                              variant="contained"
                              startIcon={<MdOutlineEdit />}
                            >
                              Edit
                            </Button>
                            <Button
                               onClick={(event) => handleDelete(_id)}
                              sx={{
                                boxShadow: 'none',
                                backgroundColor: '#FFC6C6',
                                color: '#DC2626',
                                '&:hover': {
                                  backgroundColor: '#FFC6C6',
                                  boxShadow: 'none',
                                },
                              }}
                              size="small"
                              variant="contained"
                              startIcon={<CiTrash />}
                            >
                              Delete
                            </Button>
                          </Stack>
                        </TableCell> 
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
                {loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7}>
                      <CircularProgress size="20px" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[100, 200, 300]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/*     
        <Link
          
          style={{ textDecoration: 'none' }}
          // to={`/dashboard/user-detail/${encodeURIComponent(JSON.stringify(clickedRow))}`}
          to={`/dashboard/user-detail?data=${encodedData}`}
        > */}
        <MenuItem
          onClick={() => navigate('/contractor/contractor-detail', { state: { clickedRowData } })}
          sx={{ color: 'success.main' }}
        >
          <Iconify icon={'eva:image-outline'} sx={{ mr: 2 }} />
          Detail
        </MenuItem>
        {/* </Link> */}
        <MenuItem>
          <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
          Block
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
