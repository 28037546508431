import { Container, Stack, Typography, Card } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Chat from '../components/chatMainPage/Chat';

function ChatPage() {
  return (
    <>
      <Helmet>
        <title> Chat | Page </title>
      </Helmet>
      <Chat/>
    </>
  );
}

export default ChatPage;
