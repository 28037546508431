import { useState } from 'react';
import { IoLogOutOutline, IoLogInOutline } from 'react-icons/io5';
import { FaRegEdit } from 'react-icons/fa';
import { RiFileEditLine } from 'react-icons/ri';
import Accordion from '@mui/material/Accordion';
// import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IoIosAdd, IoIosRemove } from 'react-icons/io';
import notes from '../../assets/notes.svg';
import maintance from '../../assets/maintence.svg';
import refill from '../../assets/refill.svg';
import cleaning from '../../assets/cleaning.svg';
import tour from '../../assets/tour.svg';
import emergency from '../../assets/emergency.svg';
import review from '../../assets/review.svg';
import cash from '../../assets/cash.svg';

const faqs = [
  {
    _id: '1',
    question: 'What is your return policy?',
    answer: 'We offer a 30-day return policy for all unused items.',
  },
  {
    _id: '2',
    question: 'Do you provide international shipping?',
    answer: 'Yes, we ship to over 100 countries worldwide.',
  },
  {
    _id: '3',
    question: 'How can I track my order?',
    answer: 'You can track your order using the tracking link provided in the confirmation email.',
  },
  {
    _id: '4',
    question: 'What is your return policy?',
    answer: 'We offer a 30-day return policy for all unused items.',
  },
  {
    _id: '5',
    question: 'Do you provide international shipping?',
    answer: 'Yes, we ship to over 100 countries worldwide.',
  },
  {
    _id: '6',
    question: 'How can I track my order?',
    answer: 'You can track your order using the tracking link provided in the confirmation email.',
  },
];

const maintenanceItems = [
  { name: 'Emergency', icon: emergency },
  { name: 'Bad Review', icon: review },
  { name: 'Cash', icon: cash },
];

const DetailsPanel = () => {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };
  return (
    <div className=" bg-[#d9d9d9] p-4 h-screen overflow-y-auto scrollbar-hide max-lg:pb-20 ">
      {/* Header */}
      <h3 className="font-bold text-black text-xl leading-6">Bianca - Luxury Penthouse</h3>
      <p className="text-sm text-gray-500 mt-1">Via Bozzi 51, 70124, Bari</p>

      <div className="flex justify-between my-2 gap-3  items-center">
        <h1 className="text-[10px] bg-white text-center flex-1 px-1.5 py-1 rounded-full">Multiple Bookings</h1>
        <h1 className="text-[10px] bg-white text-center px-1.5 flex-1 py-1 rounded-full">Copy Guest Link</h1>
      </div>

      <div className="flex justify-between my-2 gap-3  items-center">
        <div className=" bg-white flex-1 p-2 rounded-2xl">
          <div className="flex justify-between items-center">
            <div className="">
              <IoLogOutOutline size={26} />
            </div>
            <div>
              <FaRegEdit size={10} />
            </div>
          </div>
          <h1 className="text-[8px] mb-2">Checkin</h1>
          <h1 className="text-xs">Monday</h1>
          <h1 className="text-xs">14 Sep 2024</h1>
          <h1 className="text-xs">18:00</h1>
        </div>
        <div className=" bg-white flex-1 p-2 rounded-2xl">
          <div className="flex justify-between items-center ">
            <div className="">
              <IoLogInOutline size={26} />
            </div>
            <div>
              <FaRegEdit size={10} />
            </div>
          </div>
          <h1 className="text-[8px] mb-2">Checkout</h1>
          <h1 className="text-xs">Monday</h1>
          <h1 className="text-xs">14 Sep 2024</h1>
          <h1 className="text-xs">18:00</h1>
        </div>
      </div>

      <div className="bg-white my-2 p-3 rounded-2xl">
        <div className="text-xs space-y-1">
          <div className="flex items-center">
            <h1 className="w-[50%]">Nights:</h1>
            <h1>8</h1>
          </div>
          <div className="flex items-center">
            <h1 className="w-[50%]">Guests:</h1>
            <h1>4</h1>
          </div>
          <div className="flex items-center">
            <h1 className="w-[50%]">OTA:</h1>
            <h1>Booking.com</h1>
          </div>
          <div className="flex items-center">
            <h1 className="w-[50%]">OTA ID:</h1>
            <h1>426234634</h1>
          </div>
          <div className="flex items-center">
            <h1 className="w-[50%]">Reservation ID:</h1>
            <h1>4952</h1>
          </div>
          <div className="flex items-center">
            <h1 className="w-[50%]">Booking Value:</h1>
            <h1>134 €</h1>
          </div>
          <div className="flex items-center">
            <h1 className="w-[50%]">Status:</h1>
            <h1>Cleaning</h1>
          </div>
        </div>
      </div>

      <div className="bg-white my-2 p-3 rounded-2xl">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <img className="w-5 h-5  object-cover" src={notes} alt="John Smith" />
            <h1 className="text-[10px]">Cleaners notes</h1>
          </div>
          <div>
            <FaRegEdit size={10} />
          </div>
        </div>
        <h1 className="text-xs mt-2">L’ospite vorrebbe mangiare cavallo, portare delle salsiccie</h1>
      </div>

      <div className="bg-white my-2 p-3 rounded-2xl">
        <form className="max-w-md mx-auto">
          {/* eslint-disable-next-line */}
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
            sdfsfs
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-3 h-3 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block outline-none text-xs w-full p-1 ps-8 border-black  text-gray-900 border  rounded-full bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-50 dark:border-gray-300 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder:text-[10px] mb-3"
              placeholder="Search FAQ here..."
              required
            />
          </div>
        </form>

        <div className="h-48 overflow-auto scrollbar-hide">
          {faqs?.map((item) => (
            <Accordion
              expanded={expanded === item?._id}
              onChange={handleChange(item?._id)}
              sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === item?._id ? (
                    <IoIosRemove size={18} color="black" />
                  ) : (
                    <IoIosAdd size={18} color="black" />
                  )
                }
                sx={{ fontSize: '10px', padding: 0, color: 'black', margin: 0 }}
                className="h-6 -mt-2 -mb-1"
              >
                {item?.question}
              </AccordionSummary>
              <AccordionDetails sx={{ fontSize: '10px', padding: 0, margin: 0 }}>{item?.answer}</AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>

      <div className="bg-white my-2 p-3 rounded-2xl">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <img className="w-5 h-5  object-cover" src={maintance} alt="John Smith" />
              <h1 className="text-[12px]">No maintenance</h1>
            </div>
            <div className="flex items-center gap-2">
              <img className="w-5 h-5  object-cover" src={refill} alt="John Smith" />
              <h1 className="text-[12px]">1 Refills</h1>
            </div>
            <div className="flex items-center gap-2">
              <img className="w-5 h-5  object-cover" src={cleaning} alt="John Smith" />
              <h1 className="text-[12px]">2 Extra cleaning</h1>
            </div>
            <div className="flex items-center gap-2">
              <img className="w-5 h-5  object-cover" src={tour} alt="John Smith" />
              <h1 className="text-[12px]">No tours</h1>
            </div>
          </div>
          <div>
            <h1 className="bg-[#D9D9D9] rounded-full text-xs text-center px-2 py-1">Add extra</h1>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        {maintenanceItems.map((item, index) => (
          <div key={index} className="w-[30%] flex  justify-around items-center bg-white rounded-2xl p-2">
            <div className="flex flex-col gap-1 justify-center items-center">
              <img className="w-5 h-auto" src={item.icon} alt={item.name} />
              <h1 className="font-sans font-normal text-Black text-[10px] text-center">{item.name}</h1>
            </div>
          </div>
        ))}
      </div>


      <div className='bg-white my-2 rounded-full p-2'>
        <h1 className='text-center text-[12px] '>Tasks Calendar</h1>
      </div>
    </div>
  );
};

export default DetailsPanel;
