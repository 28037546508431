import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams, Link, useLocation, Navigate } from 'react-router-dom';

import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { CiTrash } from 'react-icons/ci';
import { DatePicker } from '@mui/x-date-pickers';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  AvatarGroup,
  Select,
  FormControl,
  FormLabel,
  InputLabel,
  TextField,
  Grid,
} from '@mui/material';
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// components
import Swal from 'sweetalert2';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections

// mock
import USERLIST from '../_mock/user';
import {
  deleteCategory,
  deleteQuestion,
  deleteUploads,
  getAllQuestions,
  getMaintance,
  updateMaintance,
  getUsers,
  getAllCleaningReport,
  deleteCleanerReport,
  getAllCleaner,
} from '../helper/backend_helper';
import appConfig from '../config';
import { SolutionListHead, SolutionListToolbar } from '../components/addSolution';




// ----------------------------------------------------------------------
// const users = [
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },
//   {
//     pic: 'http://localhost:3000/assets/images/avatars/avatar_default.jpg',
//     title: 'Lorem Ipsum dolor sit',
//   },

// ];

const TABLE_HEAD = [
  { id: '', label: 'BnB', alignRight: true },
  { id: '', label: 'Cleaner Name', alignRight: true },
  { id: '', label: 'Guest Name', alignRight: true },
  { id: '', label: 'Date', alignRight: true },
  { id: '', label: 'Start Time', alignRight: true },
  { id: '', label: 'End Time', alignRight: true },
  { id: '', label: 'Cleaning Item', alignRight: true },
  { id: '', label: 'Money Found', alignRight: true },
  { id: '', label: 'Extra', alignRight: true },
  { id: '', label: 'Expenses', alignRight: false },
  { id: '', label: 'Cleaning Cost', alignRight: true },
  { id: '', label: 'Bedsheet Cost', alignRight: true },
  {id:"", label:"Action", alignRight: true}
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CleaningReport() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [clickedRowData, setClickedRowData] = useState(null);
  const [cleaningItem, setCleaningItem] = useState('');
  const [cleaners, setCleaners] = useState([]);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(null); 
  const [originalUsers, setOriginalUsers] = useState([]);
  const [endDate, setEndDate] = useState(null); 
  const handleStartDateChange = (newValue) => {
    setStartDate(newValue); // Update state when date is selected
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue); // Update state when date is selected
  };
  async function fetchData() {
    try {
      setLoading(true);
      const usersResponse = await getAllCleaningReport();
      const filteredUsers = usersResponse?.data?.data;
      setUsers(filteredUsers);
      setOriginalUsers(filteredUsers);
      const cleanerResponse = await getAllCleaner();
      setCleaners(cleanerResponse?.data?.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = async (id) => {
    try {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateMaintance(id, headers);

      fetchData();
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'An error occur',
      });
    }
  };

  const [loading, setLoading] = useState(false);
  const handleDelete = async (event, row) => {
    try {
      const allImages = [
        ...row.cleaningImages,
        ...row.moneyFoundImages,
        ...row.expenseImages,
        ...row.extraImages,
      ];
  
      console.log("All images:", allImages); // Log the collected images
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: 'Are you sure you want to delete this Cleaning Report?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });
      if (confirmed.isConfirmed) {
   
          const headers = {
            headers: {
              'Content-Type': 'application/json',
            },
          };
          await Promise.all(
            allImages.map(async (image) => {
              const data = {
                filename: image,
              };
              await deleteUploads(data, headers); // Delete each image
            })
          );
        
  
        const header = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
   

        const response = await deleteCleanerReport(row?._id, headers);
        Swal.fire({
          icon: 'success',
          title: 'Cleaning Report deleted successfully',
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'An error occur while deleting Cleaning Report',
      });
    }
  };
  const handleOpenMenu = (event, row) => {
    setOpen(event.currentTarget);
    setClickedRowData(row);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  // const encodedData = encodeURIComponent(JSON.stringify(clickedRow));





  const handleFilter = () => {
    if (!cleaningItem && !startDate && !endDate) {
      fetchData();
      return;
    }
  
    // Adjust the selected dates to include the entire day
    const start = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
    const end = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;
  
    // Filter users based on cleaningItem and createdAt date range
    const filtered = originalUsers?.filter((user) => {
      const isCleanerNameMatch = cleaningItem ? user.cleanerName === cleaningItem : true;
  
      // Check if the user's createdAt is within the selected date range
      const isDateInRange = () => {
        if (!startDate && !endDate) return true;
        const createdAt = new Date(user.createdAt).getTime();
        if (startDate && endDate) return createdAt >= start && createdAt <= end;
        if (startDate) return createdAt >= start;
        if (endDate) return createdAt <= end;
        return false;
      };
  
      return isCleanerNameMatch && isDateInRange();
    });
  
    setUsers(filtered);
    console.log({ cleaningItem, start: new Date(start).toISOString(), end: new Date(end).toISOString() });
  };
  


const handleReset = () => {
  setCleaningItem("")
  setStartDate(null)
  setEndDate(null)
  fetchData()
}
  return (
    <>
      <Helmet>
        <title> Cleaning Report </title>
      </Helmet>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Money Found" total={filteredUsers?.reduce((acc , curr) =>(
              acc + curr.money
            ),0)} color="info" icon={'mdi-bullhorn'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Expenses" total={filteredUsers?.reduce((acc , curr) =>(
              acc + curr.expense
            ),0)}color="info" icon={'mdi-bullhorn'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Cleaning Cost" total={filteredUsers?.reduce((acc , curr) =>(
              acc + curr.cleaningCost
            ),0)} color="info" icon={'mdi-bullhorn'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Bedsheet Cost" total={filteredUsers?.reduce((acc , curr) =>(
              acc + curr.bedsheetCost
            ),0)} color="info" icon={'mdi-bullhorn'} />
          </Grid>
          </Grid>
      <Card sx={{ padding: 3,marginTop:3 }}>
        <Stack mb={3} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4">Cleaning Report</Typography>
          <Stack direction="row" alignItems="center" gap={2}>

          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <DatePicker
            slotProps={{ textField: { size: 'small' } }}
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange} // Capture date and update state
              renderInput={(params) => <TextField size='small' {...params} />} // Optional: If you want to display a TextField
            />
          </Stack>

          <Stack spacing={{ xs: 2, sm: 4 }}  direction="row" useFlexGap flexWrap="wrap">
            <DatePicker
            slotProps={{ textField: { size: 'small' } }}
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange} // Capture date and update state
              renderInput={(params) => <TextField size='small' {...params} />} // Optional: If you want to display a TextField
            />
          </Stack>




          <Stack>
            <FormControl size='small' sx={{ width: 270 }}>
              {/* <FormLabel sx={{ mb: 1 }}>Cleaning Item</FormLabel> */}
                 <InputLabel id="demo-simple-select-label">Cleaner Name</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cleaningItem}
                     label="Cleaner Name"
                onChange={(e) => setCleaningItem(e.target.value)}
              >
                {cleaners?.map((user) => (
                  <MenuItem key={user._id} value={user.name}>
                    {user.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>


          <Button
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#3A5239',
                  '&:hover': {
                    backgroundColor: '#3A5239',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
                endIcon={<Iconify icon="eva:funnel-outline" />}
                onClick={handleFilter}
              >
                Filter
              </Button>



              <Button
                sx={{
                  boxShadow: 'none',
                  backgroundColor: '#c1121f',
                  '&:hover': {
                    backgroundColor: '#c1121f',
                    boxShadow: 'none',
                  },
                }}
                variant="contained"
                endIcon={<Iconify icon="eva:refresh-outline" />}
                onClick={handleReset}
              >
                Reset
              </Button>

              </Stack>
        </Stack>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <SolutionListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={users.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const {
                    _id,
                    bnb,
                    cleanerName,
                    guestName,
                    cleaningImages,
                    moneyFoundImages,
                    expenseImages,
                    extraImages,
                    money,
                    expense,
                    extra,
                    cleaningCost,
                    bedsheetCost,
                    createdAt,
                    startTime,
                    endTime
                  } = row;

                  return (
                    <TableRow hover key={_id}>
                      <TableCell align="left">{bnb}</TableCell>
                      <TableCell align="left">{cleanerName}</TableCell>
                      <TableCell align="left">{guestName}</TableCell>
                      <TableCell align="left">{new Date(createdAt).toLocaleDateString()}</TableCell>
                      <TableCell align="left">{new Date(startTime).toLocaleTimeString()}</TableCell>
                      <TableCell align="left">{new Date(endTime).toLocaleTimeString()}</TableCell>
                      <TableCell align="left">
                        <div style={{ display: 'flex' }}>
                          {cleaningImages?.map((image, index) => (
                                <a target="_blank" rel="noopener noreferrer" href={`${appConfig.FILE_URL}${image}`}>
                            <Avatar
                              key={index} // Use index as a key; prefer a unique identifier if available
                              alt={`Image ${index + 1}`}
                              src={`${appConfig.FILE_URL}${image}`} // Ensure the URL is constructed correctly
                              sx={{ width: 40, height: 40 }} // Customize size as needed
                            />
                            </a>
                          ))}
                        </div>
                      </TableCell>

                      <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} align="left">
                        <>{money} €</>
                        <div style={{ display: 'flex' }}>
                          {moneyFoundImages?.map((image, index) => (
                                <a target="_blank" rel="noopener noreferrer" href={`${appConfig.FILE_URL}${image}`}>
                            <Avatar
                              key={index} // Use index as a key; prefer a unique identifier if available
                              alt={`Image ${index + 1}`}
                              src={`${appConfig.FILE_URL}${image}`} // Ensure the URL is constructed correctly
                              sx={{ width: 40, height: 40 }} // Customize size as needed
                            />
                            </a>
                          ))}
                        </div>
                      </TableCell>

                      <TableCell align="left">
                        <>{extra}</>
                        <div style={{ display: 'flex' }}>
                          {extraImages?.map((image, index) => (
                                <a target="_blank" rel="noopener noreferrer" href={`${appConfig.FILE_URL}${image}`}>
                            <Avatar
                              key={index} // Use index as a key; prefer a unique identifier if available
                              alt={`Image ${index + 1}`}
                              src={`${appConfig.FILE_URL}${image}`} // Ensure the URL is constructed correctly
                              sx={{ width: 40, height: 40 }} // Customize size as needed
                            />
                            </a>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} align="right">
                        <>{expense} €</>
                        <div style={{ display: 'flex' }}>
                          {expenseImages?.map((image, index) => (
                            <a target="_blank" rel="noopener noreferrer" href={`${appConfig.FILE_URL}${image}`}>
                              <Avatar
                                key={index} // Use index as a key; prefer a unique identifier if available
                                alt={`Image ${index + 1}`}
                                src={`${appConfig.FILE_URL}${image}`} // Ensure the URL is constructed correctly
                                sx={{ width: 40, height: 40 }} // Customize size as needed
                              />
                            </a>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell align="left">{cleaningCost} €</TableCell>

                      <TableCell align="left">{bedsheetCost} €</TableCell>
                      <Button
                            onClick={(event) => handleDelete(event, row)}
                            sx={{
                              boxShadow: 'none',
                              backgroundColor: '#FFC6C6',
                              color: '#DC2626',
                              '&:hover': {
                                backgroundColor: '#FFC6C6',
                                boxShadow: 'none',
                              },
                            }}
                            size="small"
                            variant="contained"
                            startIcon={<CiTrash />}
                          >
                            Delete
                          </Button>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
                {loading && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7}>
                      <CircularProgress size="20px" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>

              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/*     
        <Link
          
          style={{ textDecoration: 'none' }}
          // to={`/dashboard/user-detail/${encodeURIComponent(JSON.stringify(clickedRow))}`}
          to={`/dashboard/user-detail?data=${encodedData}`}
        > */}
        <MenuItem
          onClick={() => navigate('/contractor/contractor-detail', { state: { clickedRowData } })}
          sx={{ color: 'success.main' }}
        >
          <Iconify icon={'eva:image-outline'} sx={{ mr: 2 }} />
          Detail
        </MenuItem>
        {/* </Link> */}
        <MenuItem>
          <Iconify icon={'eva:edit-outline'} sx={{ mr: 2 }} />
          Block
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
