// component

import SvgColor from '../../../components/svg-color';
import pls from '../../../assets/PLS.png';
import Iconify from '../../../components/iconify';
import { ROLES } from '../../../constant/constant';

// ----------------------------------------------------------------------
const icon = (name) => (
  <img
    src={`/assets/icons/navbar/${name}.svg`}
    alt={name}
    style={{ width: '22px', height: '22px' }}
  />
);
const user = localStorage.getItem('technisches');
 const userData =   JSON.parse(user)
 console.log(userData?.user?.nav)







 const navConfig =[
      {
        title: 'Dashboard',
        path: '/app',
        //  icon: icon('ic_analytics'),
        icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
     
      {
        title: 'Process Bookings',
        path: '/processBookings',
        // icon: icon('ic_user'),
        icon: <Iconify icon={'eva:plus-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Users',
        path: '/users',
        // icon: icon('ic_user'),
        icon: <Iconify icon={'eva:people-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'BnB List',
        path: '/bnbList',
        // icon: icon('ic_user'),
        icon: <Iconify icon={'mdi-view-grid-plus'} width={20} sx={{ cursor: 'pointer' }} />,
      },
     
      {
        title: 'Door List',
        path: '/doorList',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'mdi-bullhorn'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Special Access',
        path: '/specialAccess',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:link-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Travel Guide',
        path: '/travelGuide', // Parent path
        icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'City',
            path: '/travelGuide/city',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Assign City',
            path: '/travelGuide/assignCity',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Category',
            path: '/travelGuide/category',
            icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Travel Guide Items',
            path: '/travelGuide/info',
            icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        ],
      },
      {
        title: 'RefillDetail',
        path: '/refill', // Parent path
        icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'Refill Items',
            path: '/refill/refillItems',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Assign Refill',
            path: '/refill/assignRefill',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Refill Requests',
            path: '/refill/refillRequest',
            icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        
        ],
      },
      {
        title: 'Extra Cleanings',
        path: '/cleaning', // Parent path
        icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'Cleaning Items',
            path: '/cleaning/cleaningItems',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Assign Cleaning',
            path: '/cleaning/assignCleaning',
            icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Cleaning Requests',
            path: '/cleaning/cleaningRequest',
            icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        
        ],
      },


      {
        title: 'Cleaners',
        path: '/cleaners', // Parent path
        icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'Cleaner List',
            path: '/cleaners/cleaner',
            // icon: icon('ic_notification_package'),
            icon: <Iconify icon={'eva:clipboard-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Cleaning Report',
            path: '/cleaners/cleaningReport',
            // icon: icon('ic_notification_package'),
            icon: <Iconify icon={'eva:clipboard-outline'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        
        ],
      },
    
   
      {
        title: 'Luggage Storage',
        path: '/luggageStorage', // Parent path
        icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
        
      {
        title: 'Storage List',
        path: '/luggageStorage/luggage',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:briefcase-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Storage Doors',
        path: '/luggageStorage/luggageDoors',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:briefcase-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Activity Logs',
        path: '/luggageStorage/luggageLogs',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:briefcase-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
        
        ],
      },




      {
        title: 'Parking',
        path: '/parking', // Parent path
        icon: <Iconify icon={'mdi:car-brake-parking'} width={20} sx={{ cursor: 'pointer' }} />,
        children: [
          {
            title: 'Parking Area',
            path: '/parking/parkingArea',
            // icon: icon('ic_notification_package'),
            icon: <Iconify icon={'mdi:car-brake-parking'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Garage',
            path: '/parking/garage',
            // icon: icon('ic_notification_package'),
            icon: <Iconify icon={'mdi:garage-variant'} width={20} sx={{ cursor: 'pointer' }} />,
          },
          {
            title: 'Private Parking',
            path: '/parking/private_parking',
            // icon: icon('ic_notification_package'),
            icon: <Iconify icon={'mdi:car-brake-parking'} width={20} sx={{ cursor: 'pointer' }} />,
          },
        
        ],
      },





    

       
      {
        title: 'FAQs',
        path: '/faqs',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:question-mark-circle-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Chat',
        path: '/chat',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:message-circle-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Maintanance',
        path: '/maintanance',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:settings-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Taxi',
        path: '/texi',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:car-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Around Us',
        path: '/aroundUs',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:home-outline'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'Auth Token',
        path: '/authToken',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:message-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
      {
        title: 'WebApp Settings',
        path: '/webappSettings',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:phone-call-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
    
      {
        title: 'Log out',
        path: '/logout',
        // icon: icon('ic_notification_package'),
        icon: <Iconify icon={'eva:log-out-fill'} width={20} sx={{ cursor: 'pointer' }} />,
      },
    ];

// eslint-disable-next-line
    let filteredNavConfig;
    if(userData?.user?.email==="timelesspuglia@gmail.com"){
       filteredNavConfig = navConfig
    }else{
      filteredNavConfig = navConfig.filter((navItem) =>
        userData?.user?.nav?.includes(navItem.path)
      );
    }
  

    export default filteredNavConfig;

  // const navConfig = getNavConfig(userData?.user?.role?.name);






//  const getNavConfig = (role) => {
//   if (role === ROLES.ADMIN) {
//     return [
//       {
//         title: 'Dashboard',
//         path: '/app',
//         //  icon: icon('ic_analytics'),
//         icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
     
//       {
//         title: 'Process Bookings',
//         path: '/processBookings',
//         // icon: icon('ic_user'),
//         icon: <Iconify icon={'eva:plus-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Users',
//         path: '/users',
//         // icon: icon('ic_user'),
//         icon: <Iconify icon={'eva:people-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'BnB List',
//         path: '/bnbList',
//         // icon: icon('ic_user'),
//         icon: <Iconify icon={'mdi-view-grid-plus'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
     
//       {
//         title: 'Door List',
//         path: '/doorList',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'mdi-bullhorn'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Special Access',
//         path: '/specialAccess',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:link-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Travel Guide',
//         path: '/travelGuide', // Parent path
//         icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         children: [
//           {
//             title: 'City',
//             path: '/travelGuide/city',
//             icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Assign City',
//             path: '/travelGuide/assignCity',
//             icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Category',
//             path: '/travelGuide/category',
//             icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Travel Guide Items',
//             path: '/travelGuide/info',
//             icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//         ],
//       },
//       {
//         title: 'Refill Detail',
//         path: '/refill', // Parent path
//         icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         children: [
//           {
//             title: 'Refill Items',
//             path: '/Refill Detail/refillItems',
//             icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Assign Refill',
//             path: '/Refill Detail/assignRefill',
//             icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Refill Requests',
//             path: '/Refill Detail/refillRequest',
//             icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
        
//         ],
//       },
//       {
//         title: 'Extra Cleanings',
//         path: '/cleaning', // Parent path
//         icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         children: [
//           {
//             title: 'Cleaning Items',
//             path: '/cleaning/cleaningItems',
//             icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Assign Cleaning',
//             path: '/cleaning/assignCleaning',
//             icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Cleaning Requests',
//             path: '/cleaning/cleaningRequest',
//             icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
        
//         ],
//       },


//       {
//         title: 'Cleaners',
//         path: '/cleaners', // Parent path
//         icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         children: [
//           {
//             title: 'Cleaner List',
//             path: '/cleaners/cleaner',
//             // icon: icon('ic_notification_package'),
//             icon: <Iconify icon={'eva:clipboard-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Cleaning Report',
//             path: '/cleaners/cleaningReport',
//             // icon: icon('ic_notification_package'),
//             icon: <Iconify icon={'eva:clipboard-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
        
//         ],
//       },
    
   
//       {
//         title: 'Luggage Storage',
//         path: '/luggageStorage', // Parent path
//         icon: <Iconify icon={'eva:shopping-bag-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         children: [
        
//       {
//         title: 'Storage List',
//         path: '/luggageStorage/luggage',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:briefcase-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Storage Doors',
//         path: '/luggageStorage/luggageDoors',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:briefcase-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Activity Logs',
//         path: '/luggageStorage/luggageLogs',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:briefcase-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
        
//         ],
//       },




//       {
//         title: 'Parking',
//         path: '/parking', // Parent path
//         icon: <Iconify icon={'mdi:car-brake-parking'} width={20} sx={{ cursor: 'pointer' }} />,
//         children: [
//           {
//             title: 'Parking Area',
//             path: '/parking/parkingArea',
//             // icon: icon('ic_notification_package'),
//             icon: <Iconify icon={'mdi:car-brake-parking'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Garage',
//             path: '/parking/garage',
//             // icon: icon('ic_notification_package'),
//             icon: <Iconify icon={'mdi:garage-variant'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
//           {
//             title: 'Private Parking',
//             path: '/parking/private_parking',
//             // icon: icon('ic_notification_package'),
//             icon: <Iconify icon={'mdi:car-brake-parking'} width={20} sx={{ cursor: 'pointer' }} />,
//           },
        
//         ],
//       },





    

       
//       {
//         title: 'FAQs',
//         path: '/faqs',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:question-mark-circle-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Chat',
//         path: '/chat',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:message-circle-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Maintanance',
//         path: '/maintanance',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:settings-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Taxi',
//         path: '/texi',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:car-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Around Us',
//         path: '/aroundUs',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:home-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Auth Token',
//         path: '/authToken',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:message-square-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'WebApp Settings',
//         path: '/webappSettings',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:phone-call-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
    
//       {
//         title: 'Log out',
//         path: '/logout',
//         // icon: icon('ic_notification_package'),
//         icon: <Iconify icon={'eva:log-out-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//     ];
//   }  if (role === ROLES.TRAVEL_GUIDER) {
//     return [
//       {
//         title: 'dashboard',
//         path: '/app',
//         //  icon: icon('ic_analytics'),
//         icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//     {
//       title: 'Travel Guide',
//       path: '/travelGuide', // Parent path
//       icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       children: [
//         {
//           title: 'City',
//           path: '/travelGuide/city',
//           icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         },
//         {
//           title: 'Assign City',
//           path: '/travelGuide/assignCity',
//           icon: <Iconify icon={'eva:shake-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         },
//         {
//           title: 'Category',
//           path: '/travelGuide/category',
//           icon: <Iconify icon={'eva:layers-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         },
//         {
//           title: 'Travel Guide Items',
//           path: '/travelGuide/info',
//           icon: <Iconify icon={'eva:globe-2-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//         },
//       ],
//     }]
//   } 
//     return [
//       {
//         title: 'dashboard',
//         path: '/app',
//         //  icon: icon('ic_analytics'),
//         icon: <Iconify icon={'eva:grid-fill'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//       {
//         title: 'Maintanance',
//         path: '/maintanance',
//         icon: <Iconify icon={'eva:settings-outline'} width={20} sx={{ cursor: 'pointer' }} />,
//       },
//     ];
  
// };



//   const navConfig = getNavConfig(userData?.user?.role?.name);

