import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';

import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { MapContainer, TileLayer, Polygon, useMapEvents, Marker } from 'react-leaflet';
import L from 'leaflet';
import currIcon from '../../assets/location.png';
import {
  createCategory,
  createTexi,
  createQuestion,
  getBnbList,
  uploadFile,
  createParking,
} from '../../helper/backend_helper';
import 'leaflet/dist/leaflet.css';

const icon = L.divIcon({
  html: `
      <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%; /* Fully rounded */
        width: 60px; /* Width of the marker */
        height: 60px; /* Height of the marker */
        padding:0
      ">
        <img 
          src="${currIcon}" 
          alt="bag-icon" 
          style="width: 30%; height: 30%;" 
        />
      </div>
    `,
  iconSize: [40, 40], // Matches the size of the outer div
  iconAnchor: [20, 40], // Anchor at the bottom center
  popupAnchor: [0, -40], // Popup above the marker
  className: '',
});
const AddParking = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ profileImage: null });

  const [color, setColor] = useState('#000000'); // Default to black
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [italianDescription, setItalianDescription] = useState('');
  const [coordinates, setCoordinates] = useState([]);
  console.log(coordinates)
  // Add coordinate on map click
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        setCoordinates((prev) => [...prev, { latitude: lat, longitude: lng }]);
      },
    });
    return null;
  };

  // Convert coordinates for Polygon
  const polygonCoordinates = coordinates.map((coord) => [coord.latitude, coord.longitude]);

  // If there are at least 3 coordinates, close the polygon
  if (coordinates.length > 2) {
    polygonCoordinates.push([coordinates[0].latitude, coordinates[0].longitude]);
  }

  const [loading, setLoading] = useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!color) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Color is required',
        });
        setLoading(false);
        return;
      }
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name is required',
        });
        setLoading(false);
        return;
      }

      if (!description) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Description is required',
        });
        setLoading(false);
        return;
      }
      if (!italianDescription) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Description is required',
        });
        setLoading(false);
        return;
      }

      if (coordinates.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Coordinates is required',
        });
        setLoading(false);
        return;
      }

      console.log();

      const transformedCoordinates = coordinates.map((coord) => [coord.longitude, coord.latitude]);

      const Categorydata = {
        name,
        color,
        description,
        italianDescription,
        coordinates: {
          type: 'MultiPoint',
          coordinates: transformedCoordinates,
        },
      };
      console.log(Categorydata);
      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await createParking(Categorydata, Categoryheaders);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Parking created successfully',
      });
      setName("")
      setColor('');
      setDescription('');
      setCoordinates([]);
      setItalianDescription('');

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Parking </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Add Parking</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >


<Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
            <TextField
              label="Enter Name"
              id="outlined-size-small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Stack>
          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Color</FormLabel>
            <TextField
              label="Enter Color"
              id="outlined-size-small"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              sx={{ mb: 2 }}
            />
            <input
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              style={{
                width: '10%',
                height: '80px',
                border: 'none',
                cursor: 'pointer',
              }}
            />
          </Stack>

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Description</FormLabel>
            <TextField
              multiline
              rows={4}
              label="Enter Description"
              id="outlined-size-small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Italian Description</FormLabel>
            <TextField
              multiline
              rows={4}
              label="Enter Italian Description"
              id="outlined-size-small"
              value={italianDescription}
              onChange={(e) => setItalianDescription(e.target.value)}
            />
          </Stack>

          <div style={{ height: '50vh', width: '100%', marginTop: 20 }}>
            <MapContainer center={[40.91551033210304, 16.759643554687504]} zoom={9} style={{ height: '100%', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; OpenStreetMap contributors"
              />
              <MapClickHandler />
              {coordinates.length > 2 && (
                <Polygon
                  positions={polygonCoordinates}
                  pathOptions={{
                    color: '#8ecae6', // Border color
                    fillColor: '#bde0fe', // Fill color
                    fillOpacity: 0.5, // Opacity of fill
                  }}
                />
              )}
              {coordinates.map((coord, index) => (
                <Marker icon={icon} key={index} position={[coord.latitude, coord.longitude]} />
              ))}
            </MapContainer>
          </div>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
        <Button onClick={() => setCoordinates([])} variant="text">
          Reset
        </Button>
      </Card>
    </>
  );
};

export default AddParking;
