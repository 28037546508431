import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    FormControl,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { Helmet } from 'react-helmet-async';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { FaArrowLeftLong } from 'react-icons/fa6';
  import { SlCloudUpload } from 'react-icons/sl';
  import Swal from 'sweetalert2';
  import { LoadingButton } from '@mui/lab';
  import {
    createAssignRefill,
    getAllRefill,
    createQuestion,
    getBnbList,
    uploadFile,
    getAllCleaningItem,
    createCleaningItem,
    createAssignCleaning,
    createLuggage,
    getBnBData,
    updateLuggage,
  } from '../../helper/backend_helper';
  
  const UpdateLuggage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userData = location?.state?.userData;
    console.log(userData.bnbType)
    const [bnb, setBnB] = useState(userData.bnb);
    const [storageName, setStorageName] = useState(userData.storageName);
    const[address , setAddress] = useState(userData?.address);
    const [openingTime, setOpeningTime] = useState(userData?.openingTime);
    const [latitude, setLatitude] = useState(userData?.coordinates?.coordinates[1]);
    const [longitude, setLongitude] = useState(userData?.coordinates?.coordinates[0]);
    const [instruction, setInstruction] = useState(userData?.videoLink);
    const [loading, setLoading] = useState(false);
 

    const handleSelectChange = async (e) => {
      setBnB(e.target.value);
  
      if (e.target.value[0]) {
        const bnbResponse = await getBnBData(e.target.value[0]);
        console.log(bnbResponse?.data?.data);
        setAddress(bnbResponse?.data?.data?.address);
        setLatitude(bnbResponse?.data?.data?.latitude);
        setLongitude(bnbResponse?.data?.data?.longitude);
      }
    };
  
    const handleInputChange = (e) => {
      setStorageName(e.target.value);
    };


  
    const [users, setUsers] = useState([]);
    async function fetchData() {
      try {
        const usersResponse = await getBnbList();
        setUsers(
          usersResponse?.data?.data?.sort((a, b) => {
            const nameA = a.BnBShortName?.toLowerCase() || ""; // Handle undefined or null values
            const nameB = b.BnBShortName?.toLowerCase() || "";
            return nameA.localeCompare(nameB); // Sort alphabetically
          })
        );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
   
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const handleSave = async () => {
      try {
        setLoading(true);
  
      
        if (bnb.length===0) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'BnB is required',
          });
          setLoading(false);
          return;
        }
        if (!storageName) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Storage Name is required',
          });
          setLoading(false);
          return;
        }
  
        if (!address) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Address is required',
          });
          setLoading(false);
          return;
        }
        if (latitude===0) {
          Swal.fire({ 
            icon: 'error',
            title: 'Error',
            text: 'Latitude is required',
          });
          setLoading(false);
          return;
        }
  
        if (longitude === 0) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Longitude is required',
          });
          setLoading(false);
          return;
        }
        if (!instruction) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Instruction is required',
          });
          setLoading(false);
          return;
        }
  
        if (!openingTime) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Opening Time is required',
          });
          setLoading(false);
          return;
        }
  
        const Categorydata = {
          bnb,
          storageName,
          address,
          openingTime,
          coordinates:{
            type: 'Point',
            coordinates: [longitude, latitude],
          },
          videoLink:instruction,
          bnbType:"entered"
        };
  
        const Categoryheaders = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
  
        const response = await updateLuggage(userData?._id,Categorydata, Categoryheaders);
        console.log('response', response);
        // Show success message
        Swal.fire({
          icon: 'success',
          title: 'Updated successfully',
        });

  
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: `${error?.response?.data?.errors[0]?.message}`,
        });
      }
    };
  
    return (
      <>
        <Helmet>
          <title> Update Luggage </title>
        </Helmet>
        <Card sx={{ padding: 3 }}>
          <Stack
            onClick={() => navigate(-1)}
            gap={3}
            sx={{ cursor: 'pointer', mb: 3 }}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <FaArrowLeftLong size={24} />
            <Typography variant="h4">Update Luggage</Typography>
          </Stack>
  
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { width: '50ch' },
            }}
            noValidate
            autoComplete="off"
          >
         
  
         <Stack>
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
              <Select
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={bnb} // This will be an array
                onChange={handleSelectChange}
                renderValue={(selected) => selected.join(', ')} 
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.BnBShortName}>
                    <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                    {user.BnBShortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Stack>
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Luggage Storage Name</FormLabel>
              <TextField
              type='text'
                label="Luggage Storage Name"
                id="outlined-size-small"
                value={storageName}
                onChange={handleInputChange}
              />
            </Stack>




            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Address</FormLabel>
              <TextField
              type='text'
                label="Enter address"
                id="outlined-size-small"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Stack>
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Enter Opening Time</FormLabel>
              <TextField
              type='text'
                label="Enter Opening Time"
                id="outlined-size-small"
                value={openingTime}
                onChange={(e) => setOpeningTime(e.target.value)}
              />
            </Stack>
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Latitude</FormLabel>
              <TextField
              type='number'
                label="Enter Latitude"
                id="outlined-size-small"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </Stack>
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Longitude</FormLabel>
              <TextField
              type='number'
                label="Enter Longitude"
                id="outlined-size-small"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </Stack>
            <Stack sx={{ marginTop: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Instruction Link</FormLabel>
              <TextField
              type='text'
                label="Enter Link"
                id="outlined-size-small"
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
              />
            </Stack>
          </Box>
          <LoadingButton
            sx={{
              width: 100,
              mt: 4,
              mb: 4,
              boxShadow: 'none',
              backgroundColor: '#3A5239',
              '&:hover': {
                backgroundColor: '#3A5239',
                boxShadow: 'none',
              },
            }}
            variant="contained"
            onClick={handleSave}
            loading={loading}
          >
            Update
          </LoadingButton>
        </Card>
      </>
    );
  };
  
  export default UpdateLuggage;
  