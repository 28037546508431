import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import { createCategory, createDoorList, getAllLuggage, getBnbList, updateDoorlist, updatePrivateParking, uploadFile } from '../../helper/backend_helper';
import appConfig from '../../config';

const UpdatePrivateParking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;



  const [formData, setFormData] = useState({ profileImage: null });

  const [bnb, setBnB] = useState(userData?.bnb);
  const [description, setDescription] = useState(userData?.description);
  const [loading, setLoading] = useState(false);
  const [italianDescription, setItalianDescription] = useState(userData?.italianDescription);

  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };



  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getBnbList();
      setUsers(
        usersResponse?.data?.data?.sort((a, b) => {
          const nameA = a.BnBShortName?.toLowerCase() || ""; // Handle undefined or null values
          const nameB = b.BnBShortName?.toLowerCase() || "";
          return nameA.localeCompare(nameB); // Sort alphabetically
        })
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);
     

      if (bnb.length===0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'BnB Name is required',
        });
        setLoading(false);
        return;
      }

     

    

      if (!description) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Description is required',
        });
        setLoading(false);
        return;
      }
    
   

      if(!italianDescription){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Italian Description is required',
        });
        setLoading(false);
        return;
      }
      // Prepare headers for multipart form data
      const headers = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      let imageUploadResponse;  // Declare it outside the block

      if (formData.profileImage) {
        const data = new FormData();
        data.append('file', formData.profileImage);
      
        imageUploadResponse = await uploadFile(data, headers);  // Assign value inside the block
        console.log(imageUploadResponse?.data?.data?.fileName);
      
        if (imageUploadResponse.status !== 200) {
          throw new Error('Image upload failed');
        }
      }
      
      // Build the Categorydata object
      const Categorydata = {
        image: imageUploadResponse?.data?.data?.fileName || userData?.image, // Use the uploaded image or fallback
        description,
        bnb,
        italianDescription,
      };
      

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updatePrivateParking(userData?._id,Categorydata, Categoryheaders);

      

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Updated successfully',
      });
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Private Parking </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update Private Parking</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >

         
          

           

          

            <Stack>
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>BnB</FormLabel>
              <Select
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={bnb} // This will be an array
                onChange={(e) => setBnB(e.target.value)}
                renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
              >
                {users?.map((user) => (
                  <MenuItem key={user._id} value={user.BnBShortName}>
                    <Checkbox checked={bnb.indexOf(user.BnBShortName) > -1} />
                    {user.BnBShortName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Stack>

          

         


            <Stack>
              <FormLabel sx={{ mb: 1 }}>Description</FormLabel>
              <TextField
                  multiline
                  rows={4}
                label="Enter Description"
                id="outlined-size-small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>

            <Stack>
              <FormLabel sx={{ mb: 1 }}>Italian Description</FormLabel>
              <TextField
                  multiline
                  rows={4}
                label="Enter Italian Description"
                id="outlined-size-small"
                value={italianDescription}
                onChange={(e) => setItalianDescription(e.target.value)}
              />
            </Stack>
          
     

          <Stack
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
           <Stack>
                {!formData.profileImage ? (
                  <img
                    src={`${appConfig.FILE_URL}${userData?.image}`}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                ) : (
                  <img
                    src={URL.createObjectURL(formData.profileImage)}
                    alt="img"
                    style={{ maxWidth: '300px', borderRadius: 10 }}
                  />
                )}
              </Stack>
            <Button
              sx={{
                width: 170,
                color: '#3A5239',
                borderColor: '#3A5239',
                '&:hover': {
                  borderColor: '#3A5239',
                  boxShadow: 'none',
                },
              }}
              component="label"
              variant="outlined"
              size="medium"
              startIcon={<SlCloudUpload />}
            >
              upload Image
              <input
                type="file"
                accept="image/*"
                name="profileImage"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Button>
          </Stack>
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Update
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdatePrivateParking;
