import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';

import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';


import {
  createGarage,
  createParking,
  updateGarage,
} from '../../helper/backend_helper';





const UpdateGarage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
  const [formData, setFormData] = useState({ profileImage: null });

  const [name, setName] = useState(userData?.name);
  const [latitude, setLatitude] = useState(userData?.coordinates?.coordinates[1]);
  const [longitude, setLongitude] = useState(userData?.coordinates?.coordinates[0]);
  const [link, setLink] = useState(userData?.link);

  const [loading, setLoading] = useState(false);


  const handleSave = async () => {
    try {
      setLoading(true);

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'name is required',
        });
        setLoading(false);
        return;
      }
   
      if (!link) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Link is required',
        });
        setLoading(false);
        return;
      }

      const Categorydata = {
        name,
        coordinates: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        link
      };
      console.log(Categorydata);
      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateGarage(userData._id,Categorydata, Categoryheaders);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Garage updated successfully',
      });

      
   

   
      

      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> Garage </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update Garage</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
         
         

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
            <TextField
              label="Enter Name"
              id="outlined-size-small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Stack>

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Link</FormLabel>
            <TextField
              label="Enter Link"
              id="outlined-size-small"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </Stack>

          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>Latitude</FormLabel>
            <TextField
            type="number"
              label="Enter Latitude"
              id="outlined-size-small"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Stack>


          <Stack sx={{ marginTop: 2 }}>
            <FormLabel sx={{ mb: 1 }}>longitude</FormLabel>
            <TextField
            type='number'
              label="Enter Longitude"
              id="outlined-size-small"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
            />
          </Stack>


         
         

        
        
        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdateGarage;
