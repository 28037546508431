import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from 'react-icons/fa6';
import { SlCloudUpload } from 'react-icons/sl';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import navConfig from '../../layouts/dashboard/nav/config';
import { createCategory, createDoorList, createUsers, getAllRoles, getBnbList, updateUsers, uploadFile } from '../../helper/backend_helper';

const UpdateRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
  const [formData, setFormData] = useState({ profileImage: null });
  const [name, setName] = useState(userData?.name);
  const [role, setRole] = useState(userData?.role?._id);
  const [mobileNumber, setMobileNumber] = useState(userData?.mobileNumber);
  const [nav, setNav] = useState(userData?.nav);
  console.log(navConfig)
  const [email, setEmail] = useState(userData?.email);
  const [password, setPassword] = useState("");
  const [loading, setLoading]=useState(false);
  const handleFileChange = (e) => {
    const { name, files } = e.target;

    setFormData({
      ...formData,
      [name]: files[0],
    });
  };

  const [users, setUsers] = useState([]);
  console.log('users', users);
  async function fetchData() {
    try {
      const usersResponse = await getAllRoles();
      console.log('usersResponse', usersResponse);
      setUsers(usersResponse?.data?.data?.roles);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      setLoading(true);

      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Name is required',
        });
        setLoading(false);
        return;
      }

      if (!email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Email is required',
        });
        setLoading(false);
        return;
      }

      if (!mobileNumber) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Phone Number is required',
        });
        setLoading(false);
        return;
      }



      if (!role) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Role is required',
        });
        setLoading(false);
        return;
      }
      
      if (nav.lenght === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Nav is required',
        });
        setLoading(false);
        return;
      }
    
      const Categorydata = {
        name,
        role,
        email,
        mobileNumber,
        nav
      };

      const Categoryheaders = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const response = await updateUsers(userData?._id,Categorydata, Categoryheaders);
      console.log('response', response);

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'User updated successfully',
      });
    
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `${error?.response?.data?.errors[0]?.message}`,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title> User </title>
      </Helmet>
      <Card sx={{ padding: 3 }}>
        <Stack
          onClick={() => navigate(-1)}
          gap={3}
          sx={{ cursor: 'pointer', mb: 3 }}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <FaArrowLeftLong size={24} />
          <Typography variant="h4">Update User</Typography>
        </Stack>

        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { width: '50ch' },
          }}
          noValidate
          autoComplete="off"
        >
          <Stack spacing={{ xs: 2, sm: 4 }} direction="row" useFlexGap flexWrap="wrap">
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Name</FormLabel>
              <TextField
                label="Enter Name"
                id="outlined-size-small"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Email</FormLabel>
              <TextField
              disabled
                label="Enter Email"
                id="outlined-size-small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>New Password</FormLabel>
              <TextField
                label="Enter New Password"
                id="outlined-size-small"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Stack>
            <Stack>
              <FormLabel sx={{ mb: 1 }}>Phone Number</FormLabel>
              <TextField
              type='number'
                label="Enter Phone Number"
                id="outlined-size-small"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </Stack>
          

            <Stack>
              <FormControl sx={{ width: 490 }}>
                <FormLabel sx={{ mb: 1 }}>Role</FormLabel>
                {/* <InputLabel id="demo-simple-select-label">BnB</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  // label="BnB"
                  onChange={(e) => setRole(e.target.value)}
                >
                  {users?.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack>
            <FormControl sx={{ width: 490 }}>
              <FormLabel sx={{ mb: 1 }}>Nav Access</FormLabel>
              <Select
                labelId="demo-multi-select-label"
                id="demo-multi-select"
                multiple
                value={nav} // This will be an array
                // onChange={(e) => setNav(e.target.value)}
                onChange={(e) => {
                  const selectedValues = e.target.value;
                  const alwaysSelected = navConfig[0]?.path; // Ensure 0th index is always selected
                  if (!selectedValues.includes(alwaysSelected)) {
                    selectedValues.push(alwaysSelected);
                  }
                  setNav(selectedValues);
                }}
                renderValue={(selected) => selected.join(', ')} // Render selected values as a comma-separated string
              >
                {navConfig?.map((user) => (
                  <MenuItem key={user.title} value={user.path}>
                    <Checkbox checked={nav.indexOf(user.path) > -1} />
                    {user.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
      

          </Stack>

        </Box>
        <LoadingButton
          sx={{
            width: 100,
            mt: 4,
            mb: 4,
            boxShadow: 'none',
            backgroundColor: '#3A5239',
            '&:hover': {
              backgroundColor: '#3A5239',
              boxShadow: 'none',
            },
          }}
          variant="contained"
          onClick={handleSave}
          loading={loading}
        >
          Save
        </LoadingButton>
      </Card>
    </>
  );
};

export default UpdateRole;
